import type { Environment } from './types';
import { sharedProdAuEnv, sharedProdEnv, sharedSoftwareEnv } from './shared';

export const env: Environment = {
  ...sharedProdEnv,
  ...sharedSoftwareEnv,
  ...sharedProdAuEnv,
  adminAppUrl: 'https://admin.skin.software',
  logoUrl: 'https://assets.skin.software/software-logo.svg',
};
